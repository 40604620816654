<div class="login">
	<div class="logo" [@crop]>
		<img src="./../../../assets/system/logo.png" alt="ToherbaLogo">
	</div>
	<div class="card shadow rounded border-top" [@crop]>
		<div class="card-body">
			<form [formGroup]="loginForm">
				<div class="form-group" style="min-width: 300px;">
					<label for="email" class="texto">Correo Electrónico</label>
					<input type="text" class="form-control error" id="email" formControlName="email"
						[ngClass]="{'is-invalid': loginForm.controls['email'].errors && loginForm.controls['email'].touched}">
					<div class="invalid-feedback">
						Ingrese un correo electrónico.
					</div>
					<div class="mb-4" *ngIf="!loginForm.controls['email'].errors || !loginForm.controls['email'].touched">
					</div>
				</div>

				<div class="form-group" style="min-width: 400px;">
					<label class=" d-flex justify-content-between align-items-center texto" for="password">Contraseña<a
							type="button" class="btn-link btn-sm" (click)="recuperarContrasena()">¿Olvidó su contraseña?</a></label>

					<input type="password" class="form-control error" id="password" formControlName="password"
						[ngClass]="{'is-invalid': loginForm.controls['password'].errors && loginForm.controls['password'].touched}">
					<div class="invalid-feedback">
						Ingrese una contraseña.
					</div>
					<div class="mb-4" *ngIf="!loginForm.controls['password'].errors || !loginForm.controls['password'].touched">
					</div>
				</div>
				<br>
				<div class="d-flex flex-column w-100" style="gap: .5rem">
					<button type="button" class="btn btn-success shadow-sm"
						style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
						(click)="iniciarSesion()">Iniciar Sesión</button>
				</div>
			</form>
		</div>
	</div>
	<div class="bg" [@slideup]>
		<img src="./../../../assets/system/Slice_1.svg" alt="BG">
	</div>
</div>
<div class="login-background"></div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuario/usuario-profile/usuario-profile.component';
import { SesionService } from './utils/sesion/sesion.service';
import { ThemeService } from './utils/theme/theme.service';
import data from './utils/sesion/routes.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  routes: any[] = [];

  constructor(
    public sesion: SesionService,
    private router: Router,
    private loading: LoadingService,
    private alerts: AlertService,
    private themeService: ThemeService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.routes = data[0].routes;
    // this.isLogged();
  }

  changePass() {
    let modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  verPerfil() {
    const modalRef = this.modalService.open(UsuarioProfileComponent, { windowClass: 'clear-modal', size: 'lg', scrollable: true });
    modalRef.componentInstance.element = this.sesion.user;
    modalRef.result.then((result) => { }, (reason) => { });
  }

  logout() {
    this.loading.show();
    this.sesion.clearSesion();
    this.loading.hide();
    this.router.navigate(['login']);
  }

  searchFn(term: string, item: any) {
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
    }
    let terms = removeAccents(term.toLowerCase()).split(' ');
    let result = true;
    let p = removeAccents(([item.nombre].join(' ')).toLowerCase());
    terms.forEach(t => {
      result = result && p.includes(t);
    });
    return result;
  }

  isLogged() {
    if (this.sesion.token) {
      this.loading.show()
      this.sesion.isLogged().subscribe({next: (res) => {
        console.log(res);
        this.sesion.saveSesion(res.token, res.usuario);
        this.loading.hide();
      }, error: (err) => {
        console.error(err);
        this.sesion.clearSesion();
        this.router.navigate(['login']);
        this.loading.hide();
        this.alerts.addAlert("No se ha podido reiniciar sesión.", "danger");
      }})
    } else {
      this.router.navigate(['login']);
    }
  }

  get showSidebarAndHeader(): boolean {
    if(this.router.url === '/login') return false;
    if(this.router.url === '/terminos') return false;
    return true;
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDropdownModule, NgbModalModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxKuvToolsModule } from 'ngx-kuv-tools';
import { NgxKuvUtilsModule } from 'ngx-kuv-utils';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuario/usuario-profile/usuario-profile.component';
import { LoginComponent } from './utils/login/login.component';
import { HomeComponent } from './components/home/home.component';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalDeleteModule } from './utils/modal-delete/modal-delete.module';
import { MarkdownModule } from 'ngx-markdown';
import { TosComponent } from './utils/tos/tos/tos.component';


const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    AppComponent,
    UsuarioPassChangeComponent,
    UsuarioProfileComponent,
    LoginComponent,
    TosComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKuvUtilsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    HttpClientModule,
    NgbModalModule,
    NgxKuvToolsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    NgSelectModule,
    ModalDeleteModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './utils/login/login.component';
import { AuthGuard } from './utils/auth/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { TosComponent } from './utils/tos/tos/tos.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'terminos', component: TosComponent },
  // Rutas Protegidas
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'clientes', loadChildren: () => import('./components/cliente/cliente.module').then(m => m.ClienteModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'usuarios', loadChildren: () => import('./components/usuario/usuario.module').then(m => m.UsuarioModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'vehiculos', loadChildren: () => import('./components/vehiculo/vehiculo.module').then(m => m.VehiculoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'revisiones', loadChildren: () => import('./components/revision/revision.module').then(m => m.RevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'avisos', loadChildren: () => import('./components/aviso/aviso.module').then(m => m.AvisoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import * as data from './routes.json';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  get username() { return this.user.username; }
  get id() { return this.user.id }
  get token(): string { return localStorage.getItem('token') ?? '' }
  get user() { let usrStr = localStorage.getItem('usuario'); if (usrStr) return JSON.parse(usrStr); else return {}; }
  get rol() { return this.user.rol_id }

  notifications: any[] = [];
  rutas: any[] = [];

  controller: string = "sesion/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  get httpOptionsFile() {
    return {
      headers: new HttpHeaders({
        'Authorization': this.token,
      })
    }
  }

  get headers() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
      })
    }
  }

  get headersBlob() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
      }),
      responseType: 'blob' as 'json',
    }
  }

  constructor(
    private http: HttpClient,
    private config: ConfigService,
  ) { }

  login(credenciales: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "login", credenciales, this.httpOptions)
      .pipe(
        map((resp: any) => {
          localStorage.setItem('id', resp.usuario.id);
          localStorage.setItem('token', resp.token);
          localStorage.setItem('usuario', JSON.stringify(resp.usuario));
          return resp.usuario;
        })
      );
  }

  isLogged(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "is-logged", this.headers);
  }

  saveSesion(token: string, user: any) {
    if(!user) return;
    localStorage.setItem("token", token);
    localStorage.setItem("id", user.id);
    localStorage.setItem("usuario", JSON.stringify(user));

    this.rutas = data[user.rol_id].routes;

    // this.oneSignal.init({ appId: '08232cee-3e09-47ba-975b-f5d070ecaf73' }).then((res) => {
    //   console.log('Init', res);
    //   this.oneSignal.getNotificationPermission((permission) => {
    //     console.log('perm', permission);

    //     if (permission != 'denied') {
    //       this.oneSignal.setSubscription(true);
    //       this.oneSignal.sendTag('id', user.id);
    //     } else {
    //       this.oneSignal.showSlidedownPrompt().then((res) => {
    //         console.log(res);
    //         // this.oneSignal.setSubscription(true);
    //         this.oneSignal.sendTag('id', user.id);
    //       }).catch((err) => {
    //         console.log(err);
    //       })
    //     }
    //   });
    // }).catch((err) => {
    //   console.log(err);
    // })
  }

  clearSesion() {
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("id");
  }

  reestablecer(credenciales: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': credenciales.token
    });
    return this.http.post(this.config.apiUrl + this.controller + "reestablecer", credenciales, { headers });
  }

  recuperar(credenciales: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "recuperar-pass", credenciales, this.httpOptions);
  }

  changePassword(pass: any) {
    return this.http.post(this.config.apiUrl + this.controller + "change-password", pass, this.headers)
  }
}

import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  datos_mensuales: any = {
    nuevos_clientes: 0,
    clientes_activos: 0,
    servicios_registrados: 0,
    servicios_motor: 0,
    servicios_diferencial: 0,
    servicios_caja: 0,
  };

  datos_anuales: any = {
    nuevos_clientes: 0,
    clientes_activos: 0,
    servicios_registrados: 0,
    servicios_motor: 0,
    servicios_diferencial: 0,
    servicios_caja: 0,
  };

  chart_mensual: any = null;
  chart_anual: any = null;

  optionsMensual = {
    title: {
      text: 'Servicios por tipo',
      left: 'left',
      top: '5%',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      right: '5%',
      top: 'center',
      orient: 'vertical',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
        },
        label: {
          show: true,
          position: 'inside',
          formatter: '{c}',
          fontWeight: 'bold',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold',
            position: 'inside',
            formatter: '{c}',
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 0, name: 'Motor' },
          { value: 0, name: 'Diferencial' },
          { value: 0, name: 'Caja' },
        ],
        color: [
          '#DCC140',
          '#54706C',
          '#73a373',
          '#DCC140',
          '#8dc1a9',
          '#ea7e53',
          '#eedd78',
          '#73a373',
          '#73b9bc',
          '#7289ab',
          '#91ca8c',
          '#f49f42'
        ]
      }
    ]
  };

  optionsAnual = {
    title: {
      text: 'Servicios por tipo',
      left: 'left',
      top: '5%',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      right: '5%',
      top: 'center',
      orient: 'vertical',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: true,
          position: 'inside',
          formatter: '{c}',
          fontWeight: 'bold',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold',
            position: 'inside',
            formatter: '{c}',
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 0, name: 'Motor' },
          { value: 0, name: 'Diferencial' },
          { value: 0, name: 'Caja' },
        ],
        color: [
          '#DCC140',
          '#54706C',
          '#73a373',
          '#DCC140',
          '#8dc1a9',
          '#ea7e53',
          '#eedd78',
          '#73a373',
          '#73b9bc',
          '#7289ab',
          '#91ca8c',
          '#f49f42'
        ]
      },
    ]
  };

  constructor(
    private service: HomeService,
    private loading: LoadingService,
    private alerts: AlertService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loading.show();
    this.service.getIndicadores().subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.datos_mensuales = res.datos_mensuales;
        this.datos_anuales = res.datos_anuales;
        this.updateGraficos();
      },
      error: (err) => {
        this.loading.hide();
        console.error(err);
        this.alerts.addAlert("No se pudo obtener la información de indicadores.", "danger");
        this.updateGraficos();
      }
    });
  }

  updateGraficos(): void {
    let options_mensual = this.optionsMensual;
    let options_anual = this.optionsAnual;
    options_mensual.series[0].data[0].value = this.datos_mensuales.servicios_motor;
    options_mensual.series[0].data[1].value = this.datos_mensuales.servicios_diferencial;
    options_mensual.series[0].data[2].value = this.datos_mensuales.servicios_caja;

    options_anual.series[0].data[0].value = this.datos_anuales.servicios_motor;
    options_anual.series[0].data[1].value = this.datos_anuales.servicios_diferencial;
    options_anual.series[0].data[2].value = this.datos_anuales.servicios_caja;

    this.chart_mensual = echarts.init(<HTMLDivElement>document.getElementById('chart_mensual'));
    this.chart_mensual.setOption(<any>options_mensual);
    this.chart_anual = echarts.init(<HTMLDivElement>document.getElementById('chart_anual'));
    this.chart_anual.setOption(<any>options_anual);
  }
}

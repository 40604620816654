<div id="project-base">
  <div *ngIf="showSidebarAndHeader">
    <div id="project-sidebar">
      <kuv-compact-sidebar [logo]="'assets/system/logo.png'" [routes]="routes"></kuv-compact-sidebar>
    </div>
    <div id="project-header">
      <ng-template #popTitle>Notificaciones</ng-template>
      <ng-template #popContent>
      </ng-template>
  
      <div ngbDropdown class="d-inline-block mx-2">
        <button class="btn btn-kuv" id="dropdownBasic1" ngbDropdownToggle>
          <i class="fas fa-user"></i><span class="ml-2 d-none d-sm-inline"> Mi Cuenta</span>
        </button>
  
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="verPerfil()">
            <div class="d-flex gap-2">
              <span class="mr-4">
                <i class="fas fa-user-alt"></i>
              </span>
              <div>Ver Perfil</div>
            </div>
          </button>
          <button ngbDropdownItem (click)="changePass()">
            <div class="d-flex gap-2">
              <span class="mr-4">
                <i class="fas fa-key"></i>
              </span>
              <div>Cambiar Contraseña</div>
            </div>
          </button>
          <button ngbDropdownItem (click)="logout()">
            <div class="d-flex gap-2">
              <span class="mr-4">
                <i class="fas fa-sign-out-alt"></i>
              </span>
              <div>Cerrar Sesión</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="project-container" [ngClass]="{'no-sidebar': !showSidebarAndHeader}">
    <kuv-loading></kuv-loading>
    <kuv-alert></kuv-alert>
    <router-outlet></router-outlet>
  </div>
</div>